import type { LinksFunction, MetaFunction } from "@vercel/remix"
import type { MetaDescriptor } from "@remix-run/react"
import { json } from "@vercel/remix"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react"
import styles from "~/styles/root.css?url"
import { Analytics } from "@vercel/analytics/react"

export const meta: MetaFunction = (): Array<MetaDescriptor> => [
  { charSet: "utf-8" },
  { name: "contentType", content: "text/html; charset=utf-8" },
  {
    title:
      "Christian Internet Radio Featuring Biblical Preaching & Teaching | RefNet",
  },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
  {
    name: "description",
    content:
      "Free 24-hour Christian internet radio. Listen to trustworthy preaching & teaching from trusted Bible teachers and theologians such as R.C. Sproul, Sinclair Ferguson, W. Robert Godfrey, John MacArthur, Stephen Nichols, Burk Parsons, Derek Thomas, & many more.",
  },
]

export const links: LinksFunction = () => [
  { rel: "shortcut icon", href: "/images/favicon.ico" },
  {
    rel: "stylesheet",
    href: styles,
  },
]

export const loader = async () => {
  return json({ GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID })
}

export default function App() {
  const { GTM_CONTAINER_ID } = useLoaderData<typeof loader>()

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
            `,
          }}
        ></script>
      </head>
      <body>
        <Analytics />
        {/* GOOGLE TAG MANAGER NOSCRIPT */}
        <noscript>
          <iframe
            title="gtm"
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}
